import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    
    path: '/Home',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/Home',
        name: 'Admin',
        component: () => import('../views/Admin.vue'),
      },
      {
        path: '/User',
        name: 'User',
        component: () => import('../views/User.vue'),
      },
      {
        path: '/Order',
        name: 'Order',
        component: () => import('../views/Order.vue'),
      },
      {
        path: '/Usdt',
        name: 'Usdt',
        component: () => import('../views/Usdt.vue'),
      },
      {
        path: '/Log',
        name: 'Log',
        component: () => import('../views/Log.vue'),
      },
      {
        path: '/Company',
        name: 'Company',
        component: () => import('../views/Company.vue'),
      },
      {
        path: '/Shop',
        name: 'Shop',
        component: () => import('../views/Shop.vue'),
      },
      {
        path: '/Note',
        name: 'Note',
        component: () => import('../views/Note.vue'),
      },
      {
        path: '/Pool',
        name: 'Pool',
        component: () => import('../views/Pool.vue'),
      },
      {
        path: '/Config',
        name: 'Config',
        component: () => import('../views/Config.vue'),
      },
    ]
  }

]


const router = new VueRouter({
  routes
})

export default router
