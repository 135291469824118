import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'


import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'


import axios from 'axios'
import './axios/config'


Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.$axios = axios

Vue.use(VXETable)


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
