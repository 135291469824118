//导入创建好的axios实例
import { request } from './request';

//导出GET方法，供其它地方使用
export function postdata(data) {
    return request({
        url: data.url,
        method: data.method,
        data: data.data
    })
}
