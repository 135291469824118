<template>
    <div class="bodypage">

        <el-container>
            <el-header>
                <ul>企业联盟后台管理</ul>
                <ul>{{ nowtime }}</ul>
                <ul @click="logout">
                    <el-button type="success" plain>安全退出</el-button>
                </ul>
            </el-header>
            <el-container>
                <el-aside width="200px">
                    <el-menu :default-active="this.$route.path" router class="el-menu-vertical-demo">
                        <el-menu-item v-for="(item, i) in navList" :key="i" :index="item.name"
                            v-show="item.rank <= authority">
                            <i :class=item.classi></i>
                            <span slot="title">{{ item.navItem }}</span>
                        </el-menu-item>
                    </el-menu>
                </el-aside>

                <el-main>
                    <article>
                        <router-view />
                    </article>
                </el-main>

            </el-container>
        </el-container>

    </div>
</template>
<script>
export default {
    name: "Home",
    data() {
        return {
            authority: 0,
            nowtime: '',
            islogin: window.localStorage.getItem('islogin'),
            addressstr: '',
            navList: [
                { name: '/Home', navItem: '管 理 员', classi: 'el-icon-s-custom', rank: 1 },
                { name: '/User', navItem: '会员管理', classi: 'el-icon-user-solid', rank: 1 },
                { name: '/Order', navItem: '订单管理', classi: 'el-icon-s-order', rank: 1 },
                { name: '/Usdt', navItem: '提现管理', classi: 'el-icon-s-cooperation', rank: 1 },
                { name: '/Log', navItem: '日志管理', classi: 'el-icon-s-ticket', rank: 1 },
                { name: '/Company', navItem: '联企管理', classi: 'el-icon-s-flag', rank: 1 },
                { name: '/Shop', navItem: '实体管理', classi: 'el-icon-s-marketing', rank: 99 },
                { name: '/note', navItem: '公告管理', classi: 'el-icon-s-comment', rank: 99 },
                { name: '/Pool', navItem: '资金管理', classi: 'el-icon-s-finance', rank: 1 },
                { name: '/Config', navItem: '系统配置', classi: 'el-icon-s-operation', rank: 99 },
            ]
        }
    },
    mounted() {
        this.nowtime = this.getdate().time_str;
        this.authority = window.localStorage.getItem('authority');
        console.log(this.authority);
    },

    methods: {
        getdate() {
            var date = new Date();
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var dates = date.getDate();
            var day = date.getDay();
            var arr = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六",];
            var time_str = "今天是:" + year + "年 " + month + "月 " + dates + "日  " + arr[day];
            return {
                time_str
            }
        },
        logout() {
            localStorage.clear();
            this.$message({
                type: 'success',
                message: '已安全退出'
            });
            setTimeout("window.location.href = '/'", 1000);
        },

        //
    }
}

</script>
<style>
.el-header {
    /* background-color: #B3C0D1; */
    /* color: #333; */
    line-height: 60px;
    display: flex;
    justify-content: space-between;
}

.el-aside {
    /* background-color: #D3DCE6; */
    /* color: #333; */
    text-align: center;
    line-height: 200px;
}

.el-main {
    text-align: center;
}

body>.el-container {
    margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
    line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
    line-height: 320px;
}

.el-menu-vertical-demo {
    overflow: hidden;
}
</style>